<template>
  <b-jumbotron
    header="403"
    class="text-center bg-blue text-danger fontStandard"
    :style="this['events/handleMinHeight']"
  >
    <p class="text-warning">Vous ne disposez pas des autorisations ou le contenu n'existe pas</p>
    <b-button variant="primary" @click="handleClick">Retour en arrière</b-button>
  </b-jumbotron>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["events/handleMinHeight"]),
  },
  methods: {
    handleClick() {
      this.$router.go(-1);
    },
  },
};
</script>
